import React from 'react';
import PropTypes from 'prop-types';
import { SmartLink } from '../../SmartLink';
import { conf } from '../../../domain/services/configService';
import { removeHtml } from '../../../domain/utils/stringUtils';
import { OptanonButton } from '../atoms/OptanonButton';

/**
 * This component shows menu items from "footer" menu, but in fact that
 * menu is being used as a "legal" menu.
 */
export const FooterMenuItems = ({ styleMenuItem }) => {
  return (
    <ul>
      {conf.menus.menuFooter.map(link => {
        if (link.class === 'ot-sdk-show-settings') {
          return (
            <li
              data-footer-item-type="legal"
              css={styleMenuItem}
              key="optanonLink"
            >
              <OptanonButton />
            </li>
          );
        }
        return (
          <li
            data-footer-item-type="legal"
            css={styleMenuItem}
            key={link.url.path}
          >
            <SmartLink
              key={link.url.path}
              to={link.url.path}
              target={link.target}
              title={removeHtml(link.title)}
            >
              {link.title}
            </SmartLink>
          </li>
        );
      })}
    </ul>
  );
};

FooterMenuItems.propTypes = {
  styleMenuItem: PropTypes.objectOf(PropTypes.any).isRequired
};
